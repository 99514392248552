



























































import Vue from 'vue'
import { Game, GameRound3 } from '@/types/Game'
import axios from 'axios'
import baseUrl from '@/config/Api'

export default Vue.extend({
	props: {
		game: {
			type: Object as () => Game,
			required: true,
		},
	},
	data() {
		return {
			textIntro: true,
			loading: false,
			gameRound: undefined as GameRound3|undefined,
			points: undefined as number|undefined,
			correct: undefined,
		}
	},
	methods: {
		start() {
			this.textIntro = false
			this.loading = true

			axios.get(
				`${baseUrl}/games/${this.game.id}/rounds/3`,
				{
					params: {
						token: this.game.token,
					},
				},
			).then((res) => {
				this.gameRound = res.data
				this.loading = false
				this.$emit('startTimer')
			}).catch((e) => {
				console.log(e)
				this.$emit('error')
			})
		},
		submit(selection: string) {
			this.loading = true

			axios.post(
				`${baseUrl}/games/${this.game.id}/rounds/3`,
				{ selection },
				{ params: { token: this.game.token } },
			).then((res) => {
				this.$emit('pauseTimer')
				this.points = res.data.points
				this.correct = res.data.correct
				this.loading = false
			}).catch((e) => {
				console.log(e)
				this.$emit('error')
			})
		},
	},
})
