import { render, staticRenderFns } from "./HeaderGame.vue?vue&type=template&id=1a7e2821&scoped=true&"
import script from "./HeaderGame.vue?vue&type=script&lang=ts&"
export * from "./HeaderGame.vue?vue&type=script&lang=ts&"
import style0 from "./HeaderGame.vue?vue&type=style&index=0&id=1a7e2821&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a7e2821",
  null
  
)

export default component.exports