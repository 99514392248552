






























































import Vue from 'vue'
import { Game, GameRound1 } from '@/types/Game'
import axios from 'axios'
import baseUrl from '@/config/Api'

export default Vue.extend({
	props: {
		game: {
			type: Object as () => Game,
			required: true,
		},
	},
	data() {
		return {
			loading: false,
			textIntro: true,
			gameRound: undefined as GameRound1|undefined,
			correct: undefined as boolean|undefined,
			explanation: undefined as string|undefined,
			selected: null as boolean|null,
		}
	},
	methods: {
		start() {
			this.textIntro = false
			this.loading = true

			axios.get(
				`${baseUrl}/games/${this.game.id}/rounds/1`,
				{
					params: {
						token: this.game.token,
					},
				},
			).then((res) => {
				this.gameRound = res.data
				this.loading = false
				this.$emit('startTimer')
			}).catch((e) => {
				console.log(e)
				this.$emit('error')
			})
		},
		submit(correct: boolean) {
			this.selected = correct
			this.loading = true
			axios.post(
				`${baseUrl}/games/${this.game.id}/rounds/1`,
				{ correct },
				{ params: { token: this.game.token } },
			).then((res) => {
				this.$emit('pauseTimer')
				this.correct = res.data.points === 100
				this.explanation = res.data.explanation
				this.loading = false
			}).catch((e) => {
				console.log(e)
				this.$emit('error')
			})
		},
	},
})
