



























































































import Vue from 'vue'
import axios from 'axios'

import Round1 from '@/components/Round1.vue'
import Round2 from '@/components/Round2.vue'
import Round3 from '@/components/Round3.vue'
import Round4 from '@/components/Round4.vue'

import HeaderGame from '@/components/HeaderGame.vue'

import baseUrl from '@/config/Api'
import { Game } from '@/types/Game'
import InstantPrize from './components/InstantPrize.vue'

// const eventDays = [
// 	new Date('2022-06-30'),
// 	new Date('2022-07-01'),
// 	new Date('2022-07-02'),
// 	new Date('2022-07-07'),
// 	new Date('2022-07-09'),
// ]

export default Vue.extend({
	name: 'App',
	components: {
		Round1,
		Round2,
		Round3,
		Round4,
		HeaderGame,
		InstantPrize,
	},
	data() {
		return {
			step: 1,
			game: null as Game|null,
			gameRound: 0,
			gameRoundTitles: {
				1: 'FAKT ODER FAKE',
				2: 'WORTGITTER',
				3: 'BUZZER BEATER',
				4: 'SCHÄTZBEFEHL',
				5: 'GRANDE FINALE',
			},
			showBrowserModal: false,
			error: false,
			// showEventDayScreen: false,
		}
	},
	// computed: {
	// 	// show second info screen on event days
	// 	isEventDay() {
	// 		let isEventDay = false
	//
	// 		eventDays.forEach((eventDay) => {
	// 			const today = new Date()
	// 			const isToday = eventDay.getDate() === today.getDate()
	// 				&& eventDay.getMonth() === today.getMonth()
	// 				&& eventDay.getFullYear() === today.getFullYear()
	//
	// 			if (isToday) {
	// 				isEventDay = true
	// 			}
	// 		})
	//
	// 		return isEventDay
	// 	},
	// },
	mounted() {
		if (!this.isMobile()) {
			this.showBrowserModal = true
		}
	},
	methods: {
		startGame() {
			// if (this.isEventDay && !this.showEventDayScreen) {
			// 	this.showEventDayScreen = true
			// 	return
			// }

			axios.post(`${baseUrl}/games`).then((res) => {
				this.game = res.data.game
				this.gameRound = 1
				this.step = 2
			}).catch((e) => {
				console.log(e)
				this.error = true
			})
		},
		isMobile() {
			return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
		},
	},
})
