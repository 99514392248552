



















































import Vue from 'vue'
import axios from 'axios'
import baseUrl from '@/config/Api'

import { Game } from '@/types/Game'

export default Vue.extend({
	props: {
		game: {
			type: Object as () => Game,
			required: true,
		},
	},
	data() {
		return {
			loading: true,
			qrCode: null as string|null,
			vipWon: false,
			vipTimeslot: null as string|null,
		}
	},
	mounted() {
		this.loading = true

		axios.get(`${baseUrl}/games/${this.game.id}/qr`, { params: { token: this.game.token } })
			.then((res) => {
				this.qrCode = res.data.code
				this.vipWon = res.data.vip
				this.vipTimeslot = res.data.vipTimeslot
				this.loading = false
			})
	},
})
